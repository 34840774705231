/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Base Minicart implementation
 * @param {Widget} Widget Modal widget for extending
 * @returns {typeof Minicart} Minicart class
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory cart
     * @class Minicart
     * @augments Widget
     * @classdesc Minicart widget (located in header and shows only button with number of items in cart), used to:
     * - Reflect actual products quantity in cart
     * - Opens {@link MinicartDialog} by click, which contains all minicart content (products list, totals, buttons etc.)
     * @property {string} data-widget - Widget name `minicart`
     * @property {string} data-cart-url URL to be used for updating minicart widget (for ex. in case of removing products from minicart)
     * @example <caption>Example of Minicart widget markup</caption>
     * <div
     *     data-widget="minicart"
     *     data-cart-url="${URLUtils.https('Cart-Show')}"
     * >
     *     <div
     *         data-ref="minicartContent"
     *         data-count="${pdict.quantityTotal}"
     *     >
     *         ... show minicart button
     *     </div>
     *     <script data-ref="template" type="template/mustache">
     *         <div data-ref="minicartContent" data-count="{{quantityTotal}}" class="b-minicart_icon">
     *             ... show minicart button
     *         </div>
     *     </script>
     * </div>
     */
    class Minicart extends Widget {
        /**
         * @description Returns Widget configuration object
         * @returns {object} Widget configuration object
         */
        prefs() {
            return {
                refMinicartContent: 'minicartContent',
                accessibilityAlerts: {},
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization logic
         * @listens "product.added.to.cart"
         * @listens "cart.updated"
         * @listens "product.updated"
         * @listens "minicart.updated"
         * @returns {void}
         */
        init() {
            this.eventBus().on('product.added.to.cart', 'reRenderMinicartWrapper');
            this.eventBus().on('cart.updated', 'reRenderMinicartWrapper');
            this.eventBus().on('product.updated', 'reRenderMinicartWrapper');
            this.eventBus().on('minicart.updated', 'reRenderMinicartWrapper');
        }

        /**
         * @description Used to obtain clear cart model object from server response
         * @param {object} response Response from server
         * @returns {object} Cart model object
         */
        normalizeCartModel(response) {
            return response.cartModel ? response.cartModel : response;
        }

        /**
         * @description Called when needs to re-render minicart (for ex. when cart / product in a cart was updated)
         * @param {object} response response from server
         * @returns {void}
         */
        reRenderMinicartWrapper(response) {
            const cart = this.normalizeCartModel(response);
            // Return if response have no Cart Model
            if (!cart.totals) {
                return;
            }
            const isShownQtyTotal = parseInt(cart.numItems, 10);

            this.render('template', {
                quantityTotal: cart.numItems,
                isShownQtyTotal: isShownQtyTotal > 0,
                isOpen: cart.isOpen
            }, this.ref(
                this.prefs().refMinicartContent
            )).then(() => {
                if (cart.showMinicart) {
                    this.showMinicart();
                }
            });
        }

        /**
         * @description A method, called when event `click` happens on minicart icon
         * - Check cart total if empty redirect to cart page
         * - Shows minicart popover
         * @listens dom#click
         * @returns {void}
         */
        showMinicart() {
            const cartQtyTotal = parseInt(this.ref(this.prefs().refMinicartContent).data('count'), 10);

            if (cartQtyTotal === 0) {
                window.location.assign(this.prefs().cartUrl);
                return;
            }

            this.eventBus().emit('minicart.show');
        }
    }

    return Minicart;
}
