import { getContentByUrl, submitFormJson } from 'widgets/toolbox/ajax';
import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType <typeof import('widgets/global/Modal').default>} Modal
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/ConfirmDialog').default>>} confirmDialog
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputSelect').default>>} inputSelect
 */

/**
 * @description Base MinicartDialog implementation
 * @param {Modal} Modal Modal widget for extending
 * @returns {typeof MinicartDialog} MinicartDialog class
 */
export default function (Modal) {
    /**
     * @category widgets
     * @subcategory cart
     * @class MinicartDialog
     * @augments Modal
     * @classdesc MinicartDialog widget, that contains all needed logic to display/update minicart component.
     * Widget manages next actions:
     * 1. Change product quantity
     * 2. Remove product (move to Wish list) from Cart
     * 3. Communicate actions results
     * 4. Re-render Minicart including products list and cart summary in case of actions like: quantity changed, product removed etc.
     * 5. Enable/disable "Secure Checkout" button to move to Checkout process depending on if there are errors in cart, which prevents moving to Checkout
     * Update of Minicart - uses re-render of Minicart wrapper with HTML-markup, which comes from server, but not based on Cart JSON model.
     * @property {string} data-widget - Widget name `minicartDialog`
     * @property {string} data-action-url - URL to be used for updating minicart widget (for ex. in case of removing products from minicart)
     * @property {object} data-accessibility-alerts - Accessibility alerts per minicart action
     * Possible values are: `quantitychanged`, `productremoved`
     * @property {string} data-action-movetowishlist - Move product to Wish list action URL
     * @example <caption>Example of MinicartDialog widget markup</caption>
     * <div
     *     ....
     *     data-widget="minicartDialog"
     *     data-disable-rendering="true"
     *     data-classes-loading="m-loading_long"
     *     data-action-url="${URLUtils.url('Cart-MiniCartShow')}"
     *     data-accessibility-alerts='{
     *         "quantitychanged": "${Resource.msg('alert.quantitychanged', 'cart', null)}",
     *         "productremoved": "${Resource.msg('alert.productremoved', 'cart', null)}",
     *         "movedtowishlist": "${Resource.msg('alert.movedtowishlist', 'cart', null)}"
     *     }'
     *     data-action-movetowishlist="${URLUtils.url('Wishlist-AddProduct').toString()}"
     * >
     *     <div
     *         class="b-minicart_panel-container"
     *         data-ref="container"
     *     >
     *         <div
     *             class="b-minicart"
     *             role="dialog"
     *             aria-modal="true"
     *             data-ref="dialog"
     *         >
     *             <div class="b-minicart-inner">
     *                 <div class="b-minicart-header">
     *                     <div data-tau="minicart_close">
     *                         <isinclude template="components/modal/closeButton"/>
     *                     </div>
     *                 </div>
     *             </div>
     *         </div>
     *     </div>
     *     <isinclude template="cart/cartRemoveProductModal"/>
     * </div>
     */
    class MinicartDialog extends Modal {
        /**
         * @description Returns Widget configuration object
         * @returns {object} Widget configuration object
         */
        prefs() {
            return {
                actionUrl: '',
                accessibilityAlerts: {},
                classesActivated: 'm-activated',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization logic
         * @listens "minicart.show"
         * @listens "product.added.to.cart"
         * @listens "cart.updated"
         * @listens "product.updated"
         * @returns {void}
         */
        init() {
            super.init();
            this.needUpdate = true;
            this.eventBus().on('minicart.show', 'showMinicart');
            this.eventBus().on('product.added.to.cart', 'setUpdateFlag');
            this.eventBus().on('cart.updated', 'setUpdateFlag');
            this.eventBus().on('product.updated', 'setUpdateFlag');
        }

        /**
         * @description Set Update Flag - in which cases Minicart should be updated
         * @param {object} response Response object from server
         * @returns {void}
         */
        setUpdateFlag(response) {
            if (response.cartModel || response.showMinicart || response.totals) {
                this.needUpdate = true;
            }
        }

        /**
         * @description A method, called when event `click` happens on minicart icon
         * - Check cart total if empty redirect to cart page
         * - Shows minicart panel
         * - Update content of the panel
         * @returns {void}
         */
        showMinicart() {
            this.showModal({
                attributes: {
                    'data-tau-unique': 'minicart_dialog'
                }
            }); // show minicart immediately for better UX
            this.ref(this.prefs().refDialog).addClass(this.prefs().classesActivated);

            this.updateContent();
        }

        /**
         * @description Update minicart panel content
         * Fetch actual content in case when it needed. Use flag `needUpdate` for this
         * @returns {void}
         */
        updateContent() {
            if (!this.needUpdate) {
                return;
            }

            this.toggleSpinner(true);

            getContentByUrl(this.prefs().actionUrl)
                .catch(() => {
                    this.eventBus().emit('alert.show', { errorCode: 500 });
                    this.toggleSpinner(false);
                    this.close();
                })
                .then((response) => {
                    this.needUpdate = false;

                    return this.render(
                        undefined,
                        {},
                        this.ref(this.prefs().refDialog),
                        String(response)
                    ).then(() => {
                        this.toggleSpinner(false);
                        this.addFocusTraps(); // enclose new content with focus trap
                    });
                });
        }

        /**
         * @description Updates quantity for concrete product in minicart widget
         * @param {inputSelect} inputSelect - Target select input in minicart widget, which triggers quantity update
         * @emits "minicart.updated"
         * @returns {void}
         */
        updateQty(inputSelect) {
            this.toggleSpinner(true);

            const uuid = inputSelect.data('uuid');
            submitFormJson(inputSelect.data('action'), {
                pid: inputSelect.data('pid'),
                uuid: uuid,
                quantity: inputSelect.getValue()
            }, 'GET')
                .catch(response => {
                    this.displayItemLevelErrorMessage(uuid, (response && response.message));
                })
                .then((response) => {
                    if (!response) {
                        return;
                    }

                    this.needUpdate = true;
                    /**
                     * @description Event to notify concerned widgets, that Minicart was updated
                     * @event "minicart.updated"
                     */
                    this.eventBus().emit('minicart.updated', response);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.quantitychanged);

                    this.updateContent();
                })
                .finally(() => {
                    this.toggleSpinner(false);
                });
        }

        /**
         * @description Called when Customer confirms product deletion in a modal
         * <br>In fact sends request to server to delete product line item from basket
         * <br>and updates minicart widget based on server response
         * @emits "minicart.remove.product"
         * @param {refElement|null} button - confirmation popup button, triggered action
         * @param {boolean} [movedToWishlist] - Optional, if we need to move item to wishlist
         * @returns {void}
         */
        confirmedRemoveProduct(button, movedToWishlist = false) {
            if (!this.removeProductLink) {
                return;
            }

            this.eventBus().emit('minicart.remove.product', this);

            const uuid = this.removeProductLink.data('uuid');
            submitFormJson(this.removeProductLink.data('removeAction') || this.removeProductLink.data('action'), {
                pid: this.removeProductLink.data('pid'),
                uuid: uuid
            }, 'POST')
                .catch(response => {
                    this.displayItemLevelErrorMessage(uuid, (response && response.message));
                })
                .then((response) => {
                    if (!response) {
                        return;
                    }

                    this.needUpdate = true;
                    this.eventBus().emit('minicart.updated', response.basket);
                    this.accessibilityAlert(
                        movedToWishlist
                            ? this.prefs().accessibilityAlerts.movedtowishlist
                            : this.prefs().accessibilityAlerts.productremoved
                    );

                    this.updateContent();
                });

            this.removeProductLink = null;
        }

        /**
         * @description Display line item level error message.
         * Usually comes with ajax request to remove product or update quantity,
         * and in case if this product is no longer in a basket.
         * @param {string} lineItemUUID - A line item UUID in minicart.
         * @param {string} errorMessage - An error message to show.
         * @returns {void}
         */
        displayItemLevelErrorMessage(lineItemUUID, errorMessage) {
            this.ref(`miniCartItemErrorMessage-${lineItemUUID}`)
                .show().setText(errorMessage || '');
        }

        /**
         * @description Shows `remove product` dialog for Customer
         * @param {refElement} link link which is clicked by user
         * @returns {void}
         */
        removeProduct(link) {
            this.removeProductLink = link;
            this.getById('confirmDialog', (/** @type {confirmDialog} */ dialog) => dialog
                .showModal({ productName: link.data('name') }));
        }

        /**
         * @description Executes when user clicks on product details link in the minicart.
         * Usually used by analytics etc.
         * @emits "minicart.product.link.click"
         * @param {refElement} link - clicked product tile link
         * @returns {void}
         */
        onProductLinkClick(link) {
            this.eventBus().emit('minicart.product.link.click', link);
        }

        /**
         * @description Shows spinner bar in widget once any update operations (server calls) are pending
         * @param {boolean} isBusy - show / hide spinner
         * @returns {void}
         */
        toggleSpinner(isBusy) {
            this.ref('dialog')
                .removeClass(this.prefs().classesLoading)
                .attr('aria-busy', isBusy.toString());

            if (this.longWaitingTimeout) {
                this.longWaitingTimeout();
            }

            if (isBusy) {
                this.longWaitingTimeout = timeout(() => {
                    this.ref('dialog')
                        .addClass(this.prefs().classesLoading);
                }, 1000);
            }
        }

        /**
         * @description Fires `alert.show` event to be handled with Global Alerting System
         * @param {string} accessibilityAlert - alert string
         * @returns {void}
         */
        accessibilityAlert(accessibilityAlert) {
            this.eventBus().emit('alert.show', {
                accessibilityAlert
            });
        }

        /**
         * @description Move product from minicart to wishlist.
         * In fact this functionality implemented in 2 steps:
         * 1. Add product to wishlist
         * 2. Remove product from minicart
         * This is done due to possible backend code duplication in one universal endpoint
         * @returns {void}
         */
        moveProductToWishlist() {
            if (this.removeProductLink) {
                submitFormJson(this.prefs().actionMovetowishlist, {
                    pid: this.removeProductLink.data('pid')
                }).then(() => {
                    this.confirmedRemoveProduct(null, true);
                });
            }
        }
    }

    return MinicartDialog;
}
